import { Injectable } from '@angular/core';
import { Router, UrlSegment } from '@angular/router';

import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { Storage } from '@capacitor/storage';
import { finalize, map } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class UserService {

  public isLoggedIn = false;

  public userDetails: any = {
    token: '',
    status: '',
    user: {}
  };
  public userDetails$: BehaviorSubject<any> = new BehaviorSubject(null)
  hasNewMatches:boolean=false
  otherUserDetail: any;

  constructor(public apollo: Apollo, private route: Router) { }

  async UserData() {
   
   
    const data = await Storage.get({ key: 'userdetails' });

    try {
      const userdata = JSON.parse(data.value);
    
      if (userdata) {
        this.isLoggedIn = true;
        this.userDetails = userdata;
        this.getCurrentUser().subscribe( res =>{
          this.userDetails = res.data
          this.userDetails$.next(res.data);
       
         
        })
      }
      else {
        this.isLoggedIn = false; 
        this.finalizeInit();
      }
      finalize(() =>this.finalizeInit())
    }
    catch (e) {
      this.finalizeInit();
    }
  }
  initRoute(): string{
    let r = "";
  
    switch (this.userDetails.user.status) {
      case "unverified":
        r = "verify";
        break;
      case "verified":
        r = "register-preferences";
        break;
      case "active":
        r = "/dashboard/match-list";
        break;
      case "deactive":
        r = "/dashboard/match-list";
        break;
      case "suspend":
        r = "/dashboard/match-list"
        break;
    }
    return r
  }
  pollerUserData(){
    setInterval( ()=>{
     this.getCurrentUser().subscribe( res=>{
       this.userDetails$.next(res.data)
     })
    },5000)
  }
  getCurrentUser(){
    console.log('GCURSER')
   const userId: string = this.userDetails.user.id;
    let variables = {
      userId: parseFloat(userId)
    };
    return this.apollo.watchQuery({
      query: gql`
    query($userId:Float!){
      user(id:$userId){
        id
        status
          email
          birthday
          firstName
          lastName
          gender
          postalcode
          hasLittleChildren
          hasAdultChildren
          isSmoker
          isDrinker
          bio
          locationName
          hasNewMatches
        
      }
    }`,variables,
    fetchPolicy:'network-only'
  }).valueChanges
}
  getUser(userId: string){
   
      let variables = {
        userId: parseFloat(userId)
      };
      return this.apollo.watchQuery({
        query: gql`
      query($userId:Float!){
        user(id:$userId){
          id
          status
            email
            birthday
            firstName
            lastName
            gender
            postalcode
            hasLittleChildren
            hasAdultChildren
            isSmoker
            isDrinker
            bio
            locationName
            avatar
            hasNewMatches
          
        }
      }`,variables,
      fetchPolicy:'network-only'
    }).valueChanges
  }

  getUserPreferences(userId: string){
   
    let variables = {
      userId: parseFloat(userId)
    };
    return this.apollo.watchQuery({
      query: gql`
    query($userId:Float!){
      userPreference(id:$userId){
        id
        user { id postalcode}
        ageRangeEnd
        ageRangeStart
          gender
          postalcode
          hasLittleChildren
          hasAdultChildren
          locationRange
          isSmoker
          isDrinker
          
        
      }
    }`,variables,
    fetchPolicy:'network-only'
  }).valueChanges
}

  userLikeBack(userId: string, matchUserId: string) {
    const data = {
      userId,
      matchUserId,
      matchDate: new Date().toUTCString(),
      status: "active"
    }
    return this.apollo.mutate({
      mutation:gql`
      mutation($data:CreateMatchInput!){
        userLikeBack(data:$data){
          id
         
       
        }
      }`,variables: {data}
  })
    
  }


  finalizeInit() {
    const initComplete = new Event('initComplete');
    window.dispatchEvent(initComplete);
  }

  async logout() {
   
    await Storage.remove({ key: 'userdetails' });
    await Storage.clear();
    this.isLoggedIn = false;
    this.userDetails.user = {}
  
    this.route.navigate(['/login']);
  }

  public signIn(data) {
    const variables = {
      ...data
    };
    return this.apollo.watchQuery({
      query: gql`
        query($password:String,$email:String!) {
          userLogin(password: $password,email:$email) {
            status
            token
            user{
                id
                email
                password
                firstName
                lastName
                avatar
                birthday
                gender
                bio
                status
                postalcode
                hasLittleChildren
                hasAdultChildren
                isSmoker
                isDrinker
                hasNewMatches
                memberLevelExpires
                # fishPond{
                #   id
                #   status
                # }
            }
        }
        }
      `,
      variables,
    }).valueChanges;
  }

  register(data) {
    data.isSmoker = data.isSmoker.toString();
    return this.apollo.mutate({
      mutation: gql`
      mutation($data:CreateUserInput!){
        createUser(data:$data){
          id
          email
          firstName
          lastName
          avatar
          birthday
          gender
          bio
          status
        }
      }`,
      variables: { data },
    }).pipe(
      map((res: any) => {
     //   console.log('register', res.data.createUser);
        return res.data.createUser;
      })
    );
  }

  createUserPreference(data) {
    return this.apollo.mutate({
      mutation: gql`
      mutation($data: UserPreferenceInput!){
        createUserPreference(data: $data){
          id
          ageRangeStart
          ageRangeEnd
          gender
          postalcode
          hasLittleChildren
          hasAdultChildren
          isSmoker
          isDrinker
        }
      }`,
      variables: { data },
    }).pipe(
      map((res: any) => {
        return res.data.createUserPreference;
      })
    );
  }

  updateUserData(id, data) {
  
    delete data.__typename;
    delete data.email;
    delete data.memberLevelExpires;
    delete data.id;
    delete data.password;
    delete data.locationName;
 
    const variables = {
      id: parseFloat(id),
      data: {
        ...data
      }
    };
    return this.apollo.mutate({
      mutation: gql`
      mutation($id:Float!,$data:UpdateUserInput!){
        updateUser(id:$id,data:$data){
        id
        email
        password
        firstName
        lastName
        avatar
        birthday
        gender
        bio
        status
        postalcode
        hasLittleChildren
        hasAdultChildren
        isSmoker
        isDrinker
        memberLevelExpires
        hasNewMatches
        }
      }`,
      variables,
    }).pipe(
      map((res: any) => {
        this.userDetails.user = res.data.updateUser;
        const loginInfo = JSON.stringify(this.userDetails);
        Storage.set({ key: 'userdetails', value: loginInfo });
        return res.data.updateUser;
      })
    );
  }
  updateUserPreference(preferenceId: number, data: any) {
   
   
    delete data.__typename;
    data.userId =  parseInt(this.userDetails.user.id);
    data.ageRangeStart = parseInt(data.ageRangeStart)
    data.ageRangeEnd = parseInt(data.ageRangeEnd)
    data.locationRange = parseInt(data.locationRange)
   

    let variables: {
     
      data: any
    } = {
    
      data: {
        ...data
      }
    };
  
   // console.log(variables.preferenceId, "VARS")
    return this.apollo.mutate({
      mutation: gql`
      mutation($data:UserPreferenceInput!){
        updateUserPreference(preferenceId:${preferenceId}, data:$data){
        id
        
        }
      }`,
      variables,
    });
  }
  

  updateUserPassword(id, password) {
   
    const variables = {
      id: parseFloat(id),
      password
    };
    return this.apollo.mutate({
      mutation: gql`
      mutation($id:Float!,$password:String!){
        updateUserPassword(id:$id,password:$password)
        {
          id
          email
        }
      }`, variables,
    });
  }

  deleteUser(id) {
    const variables = {
      id: parseFloat(id)
    };
    return this.apollo.mutate({
      mutation: gql`
      mutation($id:Float!){
        deleteUser(id:$id)
      }`, variables,
    });

  }

  getLikeUsers(userId) {
    const variables = {
      userId: parseFloat(userId)
    };
    return this.apollo.watchQuery({
      query: gql`
      query($userId:Float!){
        UserPondLikes(userId:$userId) {
          id
          fish{
            id
            email
            firstName
            lastName
            avatar
            gender birthday locationName
          }
          status
        }
      }`, variables,
        fetchPolicy:'network-only'
    }).valueChanges;
  }
  emailExists(email){
   const variables = {
     email:email
   } 
   return this.apollo.watchQuery({
     query:gql`
     query($email:String!){
       emailExists(email:$email)
     }`,
     variables,
     fetchPolicy:'network-only'
   }).valueChanges;
  }
  getRejectUsers(userId) {
    const variables = {
      userId: parseFloat(userId)
    };
    return this.apollo.watchQuery({
      query: gql`
      query($userId:Float!){
        UserPondRejects(userId:$userId) {
          id
          fish{
            id
            email
            firstName
            lastName
            avatar
            gender birthday locationName
          }
          status
        }
      }`, variables,
        fetchPolicy:'network-only'
    }).valueChanges;
  }
  getLikeMeUsers(userId) {
    const variables = {
      userId: parseFloat(userId)
    };
    return this.apollo.watchQuery({
      query: gql`
      query($userId:Float!){
        UserPondLikesMe(userId:$userId) {
          id
          user{
            id
            email
            firstName
            lastName
            avatar
            gender birthday locationName
          }
          status
        }
      }`, variables,
      fetchPolicy:'network-only'
    }).valueChanges;
  }

  getUserMatches() {
    
     
      let userId = this.userDetails.user.id.toString()
    
    return this.apollo.watchQuery({
      query: gql`
      query{
        UserMatches(userId:"${userId}") {
          id
          matchUser{
            id
            email
            firstName
            lastName
            avatar
            gender
            birthday
            locationName
          }
          matchDate
          status
        }
      }`, fetchPolicy:"network-only"
    }).valueChanges;
  }


  uploadImageTest(base64Img) {
    return this.apollo.query({
      query: gql`
        query($base64Img: String!) {
          imageUploadTest(base64Img: $base64Img)
        }
      `,
      variables: {base64Img}
    });
  }

  updateAvatar(base64Img) {
   
    return this.apollo.mutate({
      mutation: gql`
        mutation($userId: Float!, $avatar: String!) {
          updateAvatar(userId: $userId, avatar: $avatar) {
            id,
            avatar
          }
        }
      `,
      variables: {userId: parseFloat(this.userDetails.user.id), avatar: base64Img }
    }).pipe(
      map((res: any) => {
        this.userDetails.user.avatar = res.data.updateAvatar.avatar;
        return res.data.updateAvatar.avatar;
      })
    );
  }

  /**
   * to create user photo in profile page
   * @param base64Img : base64 image string without 'data:image\/(png|jpg|jpeg);base64,'
   * @param note : description about photo
   * @returns UserPhoto
   */
  createPhoto(base64Img, note) {
    return this.apollo.mutate({
      mutation: gql`
        mutation($data: UserPhotoInput!) {
          createPhoto(data: $data) {
            id,
            imageUrl,
            imageNote
          }
        }
      `,
      variables: {data: {imageUrl: base64Img, imageNote: note, userId: this.userDetails.user.id}}
    }).pipe(
      map((res: any) => {
        return res.data.createPhoto;
      })
    );
  }

  getUserPhotos(userId: number = this.userDetails.user.id) {
    return this.apollo.query({
      query: gql`
        query($id: String!) {
          userPhotos(userId: $id) {
            id
            imageUrl,
            imageNote
          }
        }
      `,
      variables: {id: userId}
    }).pipe(
      map((res: any) => {
        return res.data.userPhotos;
      })
    );
  }

  deleteUserPhoto(photoId) {
    return this.apollo.mutate({
      mutation: gql`
        mutation($id: String!) {
          deleteUserPhoto(id: $id) {
            id
          }
        }
      `,
      variables: {id: photoId}
    }).pipe(
      map((res: any) => {
        return res.data.deleteUserPhoto;
      })
    );
  }
  rotateUserPhoto(photoId) {
    return this.apollo.mutate({
      mutation: gql`
        mutation($id: String!) {
          rotateUserPhoto(id: $id) {
            id
          }
        }
      `,
      variables: {id: photoId}
    }).pipe(
      map((res: any) => {
        return res.data.rotateUserPhoto;
      })
    );
  }
}
