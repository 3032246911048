import { Component, OnInit } from '@angular/core';
import { PopoverController, ToastController } from '@ionic/angular';
import {NgForm} from '@angular/forms';
import { UserService } from 'src/app/services/user.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-update-password',
  templateUrl: './update-password.component.html',
  styleUrls: ['./update-password.component.scss'],
})
export class UpdatePasswordComponent implements OnInit {
  data
  passwordType: any = 'password'
  showPassword: any = false
  constructor(private popup: PopoverController, private user: UserService,
   public toaster: ToastController ,private route:Router) { }

  ngOnInit() {
    console.log("here")
  }
  cancel() {
    this.popup.dismiss()
  }
  async submit(form) {
    console.log(form.value)
    if (form.value.password == form.value.password2) {
      console.log(this.data)
      this.user.updateUserPassword(this.data, form.value.password).subscribe( async( res: any) => {
        console.log(res)
           const toast = await this.toaster.create({
           message: 'Password changed',
           duration: 4000
    });
        toast.present();
         this.cancel()
       
      }) 
    }
     else {
           const toast = await this.toaster.create({
           message: 'Password Mismatch',
           duration: 4000
    });
      toast.present();
     
      }
  }
    onPasswordToggle() {
    this.showPassword = !this.showPassword
    if (this.passwordType === 'password') {
      this.passwordType = 'text'
    } else {
      this.passwordType = 'password'
    }
  }
   
}
