import { Component, OnInit } from "@angular/core";

import { Platform } from "@ionic/angular";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";
import { UserService } from "./services/user.service";
import { Router } from "@angular/router";
import { io } from "socket.io-client";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
  styleUrls: ["app.component.scss"],
})
export class AppComponent implements OnInit {
  public selectedIndex = 0;

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private user: UserService,
    private router: Router
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(async () => {
      this.statusBar.styleDefault();
      await this.user.UserData();
      this.splashScreen.hide();
      window.addEventListener("initComplete", (ans) => {
        if (this.user.isLoggedIn === false) {
          this.router.navigate(["home"]);
        } else {
          const nextRoute = this.user.initRoute();

          this.router.navigate([nextRoute]);
        }
      });
    });
  }

  ngOnInit() {
    //   const path = window.location.pathname.split('folder/')[1];
    //   if (path !== undefined) {
    //     this.selectedIndex = this.appPages.findIndex(page => page.title.toLowerCase() === path.toLowerCase());
    //   }
  }
  putEarsOn() {
    //Connect to a WebSocket Service and listen for changes.
    //channels:  match_userid , chatMessage_userid ,

    var wsUri = environment.graphQL.wsUri;
    const socket = io(wsUri);
    // client-side
    socket.on("connect", () => {
      console.log(socket.id); // x8WIv7-mJelg7on_ALbx
    });

    socket.on("disconnect", () => {
      console.log(socket.id); // undefined
    });
    // client-side
    socket.on("hello", (arg) => {
      console.log(arg); // world
    });
  }

  logout() {
    this.user.logout();
  }
}
