import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth.guard';


const routes: Routes = [
  {
    path: '',
    redirectTo: 'alpha',
    pathMatch: 'full'
  },
    {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then(m => m.HomePageModule)
  },
  {
    path: 'dashboard',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/dashboard/dashboard.module').then( m => m.DashboardPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/auth/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'walkthrough',
    loadChildren: () => import('./pages/auth/walkthrough/walkthrough.module').then( m => m.WalkthroughPageModule)
  },
  {
    path: 'register',
    loadChildren: () => import('./pages/auth/register/register.module').then( m => m.RegisterPageModule)
  },
  {
    path: 'forgot',
    loadChildren: () => import('./pages/auth/forgot-password/forgot-password.module').then( m => m.ForgotPasswordPageModule)
  },
  {
    path: 'verify',
    loadChildren: () => import('./pages/auth/verify/verify.module').then( m => m.VerifyPageModule)
  },
  {
    path: 'verified',
    loadChildren: () => import('./pages/auth/verified/verified.module').then( m => m.VerifiedPageModule)
  },
  {
    path: 'intro',
    loadChildren: () => import('./pages/intro/intro.module').then( m => m.IntroPageModule)
  },
  {
    path: 'settings/about',
    loadChildren: () => import('./pages/setting/about/about.module').then(m => m.AboutPageModule)
  },
  {
    path: 'settings/account',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/setting/account/account.module').then(m => m.AccountPageModule)
  },
  {
    path: 'settings/basic-info',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/setting/basic-info/basic-info.module').then(m => m.BasicInfoPageModule)
  },
  {
    path: 'settings/profile',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/profile/profile.module').then(m => m.ProfilePageModule)
  },
  {
    path: 'settings/blocked-users',
    loadChildren: () => import('./pages/setting/blocked-users/blocked-users.module').then(m => m.BlockedUsersPageModule)
  },
  {
    path: 'settings/faq',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/setting/faq/faq.module').then(m => m.FaqPageModule)
  },
  {
    path: 'settings/payments',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/setting/payments/payments.module').then(m => m.PaymentsPageModule)
  } ,
    {
    path: 'settings/preference',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/setting/preferences/preferences.module').then(m => m.PreferencesPageModule)
  },   {
    path: 'settings/matches',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/setting/matches/matches.module').then( m => m.MatchesPageModule)
  },
  {
    path: 'settings/likes-me',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/setting/likes-me/likes-me.module').then( m => m.LikesMePageModule)
  },
  {
    path: 'settings/hearted-profile',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/setting/hearted-profile/hearted-profile.module').then( m => m.HeartedProfilePageModule)
  },
  {
    path: 'settings/photo-gallery',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/setting/photo-gallery/photo-gallery.module').then( m => m.PhotoGalleryPageModule)
  },
  {
    path: 'settings/user-profile/:userId',
    loadChildren: () => import('./pages/setting/user-profile/user-profile.module').then( m => m.UserProfilePageModule)
  },
  {
    path: 'settings/rejected-profile',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/setting/rejected-profile/rejected-profile.module').then( m => m.RejectedProfilePageModule)
  },
  {
    path: 'register-preferences',
    loadChildren: () => import('./pages/auth/register-preferences/register-preferences.module').then( m => m.RegisterPreferencesPageModule)
  },
  {
    path: 'alpha',
    loadChildren: () => import('./alpha/alpha.module').then( m => m.AlphaPageModule)
  },
  
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
